<template>
  <v-card class="mx-auto" :disabled="loading">
    <v-card-title>
      <v-row dense>
        <v-col cols="12" xs="12" md="8">
          <span
            class="text-h6 ml-1"
            v-text="
              $route.meta.title +
              (tbl_data.length > 0 ? ' (' + tbl_data.length + ')' : '')
            "
          />
        </v-col>
        <v-col cols="12" xs="12" md="4" class="text-right">
          <v-tooltip bottom>
            <template v-slot:activator="{ on }">
              <v-btn
                v-on="on"
                fab
                x-small
                class="ml-1"
                color="info"
                @click.stop="faqs_dlg = true"
                :loading="faqs_ldg"
                :disabled="faqs.length == 0"
              >
                <v-icon> mdi-help </v-icon>
              </v-btn>
            </template>
            <span v-text="'Ayuda'" />
          </v-tooltip>
        </v-col>
        <v-col cols="12" xs="12" md="8">
          <v-autocomplete
            label="Contratantes(s)"
            v-model="contractor_ids"
            item-value="id"
            :items="contractors"
            :loading="contractors_ldg"
            :item-text="(v) => v.name"
            dense
            multiple
            @change="getTableData"
          />
        </v-col>
        <v-col v-if="tbl_data.length > 0" cols="12" xs="12" md="4">
          <v-text-field
            v-model="tbl_search"
            append-icon="mdi-magnify"
            label="Buscar..."
            single-line
            hide-details
            dense
          />
        </v-col>
      </v-row>
    </v-card-title>
    <v-card-text>
      <v-row dense>
        <v-col cols="12">
          <v-data-table
            :headers="tbl_headers"
            :search="tbl_search"
            :items="tbl_data"
            :loading="loading"
            :items-per-page="15"
            dense
          >
            <template v-slot:item.key="{ item, index }">
              <b v-text="index + 1" />
            </template>
            <template
              v-slot:item.insured_medical_treatment.follow_up_date="{ item }"
            >
              <div v-if="item.insured_medical_treatment">
                <div>
                  {{ item.insured_medical_treatment.follow_up_date }}
                </div>
                <div
                  :class="
                    (item.insured_medical_treatment.days > 3
                      ? 'orange'
                      : 'red') + '--text'
                  "
                >
                  {{ item.insured_medical_treatment.days }}
                </div>
              </div>
              <div v-else>-</div>
            </template>
            <template v-slot:item.action="{ item }">
              <div class="text-right">
                <v-tooltip left>
                  <template v-slot:activator="{ on }">
                    <v-btn
                      v-on="on"
                      color="error"
                      x-small
                      fab
                      class="ml-1"
                      @click.prevent="
                        insuredMedicalTreatmentDesactivate(item.id)
                      "
                    >
                      <v-icon> mdi-shield-off </v-icon>
                    </v-btn>
                  </template>
                  <span v-text="'Quitar tratamiento médico'" />
                </v-tooltip>
                <v-tooltip left>
                  <template v-slot:activator="{ on }">
                    <v-btn
                      v-on="on"
                      color="pink"
                      dark
                      x-small
                      fab
                      class="ml-1"
                      @click.prevent="insuredCategoryDlg(item)"
                    >
                      <v-icon> mdi-account-switch</v-icon>
                    </v-btn>
                  </template>
                  <span v-text="'Categorizar'" />
                </v-tooltip>
                <v-tooltip left>
                  <template v-slot:activator="{ on }">
                    <v-btn
                      v-on="on"
                      color="info"
                      x-small
                      fab
                      class="ml-1"
                      @click.prevent="sinisterRepDlg(item)"
                    >
                      <v-icon> mdi-clipboard-list</v-icon>
                    </v-btn>
                  </template>
                  <span v-text="'Siniestralidad'" />
                </v-tooltip>
                <v-tooltip left>
                  <template v-slot:activator="{ on }">
                    <v-btn
                      v-on="on"
                      color="success"
                      x-small
                      fab
                      class="ml-1"
                      @click.prevent="historyRepDlg(item)"
                    >
                      <v-icon> mdi-file </v-icon>
                    </v-btn>
                  </template>
                  <span v-text="'Historial médico'" />
                </v-tooltip>
                <v-tooltip left>
                  <template v-slot:activator="{ on }">
                    <v-btn
                      v-on="on"
                      color="purple"
                      dark
                      x-small
                      fab
                      class="ml-1"
                      @click.prevent="
                        insuredMedicalTreatmentsDlg(
                          item.id,
                          item.credential,
                          item.enrollment,
                          item.full_name
                        )
                      "
                    >
                      <v-icon> mdi-list-box</v-icon>
                    </v-btn>
                  </template>
                  <span v-text="'Observaciones del tratamiento'" />
                </v-tooltip>
              </div>
            </template>
          </v-data-table>
        </v-col>
      </v-row>
    </v-card-text>
    <v-dialog v-model="sinister_rep_dlg" scrollable persistent max-width="1800">
      <v-card
        tile
        :disabled="sinister_rep_dlg_ldg"
        :loading="sinister_rep_dlg_ldg"
      >
        <v-toolbar dark dense>
          <v-toolbar-title> SINIESTRALIDAD </v-toolbar-title>
          <v-spacer />
          <v-toolbar-items>
            <v-btn icon dark @click="sinister_rep_dlg = false">
              <v-icon> mdi-close </v-icon>
            </v-btn>
          </v-toolbar-items>
        </v-toolbar>
        <v-card-text v-if="sinister_rep">
          <v-row dense>
            <v-col cols="12" />
            <v-col cols="12">
              <v-divider />
            </v-col>
            <v-col cols="12">
              <h4 v-text="insured.credential + ' | ' + insured.full_name" />
            </v-col>
          </v-row>
          <v-row dense v-for="(icd, i) in sinister_rep.list" :key="i">
            <v-col cols="12">
              <span class="text-caption" v-text="icd.code + ' | ' + icd.icd" />
            </v-col>
            <v-col cols="12">
              <v-simple-table dense>
                <template v-slot:default>
                  <thead>
                    <tr>
                      <th v-text="'#'" />
                      <th v-text="'Tipo'" />
                      <th v-text="'Proveedor'" />
                      <th v-text="'T. Prov. | Espec.'" />
                      <th v-text="'Folio'" />
                      <th v-text="'Fecha'" />
                      <th v-text="'Padecimiento'" />
                      <th v-text="'Siniestro'" />
                      <th v-text="'Núm. Siniestro'" />
                      <th v-text="'Deducible'" />
                      <th v-text="'Coaseguro %'" />
                      <th v-text="'Facturado'" />
                      <th v-text="'Monto'" />
                      <th />
                    </tr>
                  </thead>
                  <tbody>
                    <tr v-for="(item, i) in icd.items" :key="i">
                      <td v-text="`${i + 1}`" />
                      <td v-text="item.type" />
                      <td v-text="item.provider" />
                      <td v-text="item.provider_type" />
                      <td v-text="item.folio" />
                      <td v-text="item.date" />
                      <td v-text="item.accident ? 'ACCIDENTE' : 'ENFERMEDAD'" />
                      <td
                        v-text="item.initial ? 'INICIAL' : 'COMPLEMENTARIO'"
                      />
                      <td v-text="item.sinister_number" />
                      <td v-text="numberFormat(item.deducible_amount)" />
                      <td v-text="item.coinsurance" />
                      <td>
                        <v-icon small :color="item.bill ? 'success' : ''">
                          mdi-{{ item.bill ? "check" : "close" }}
                        </v-icon>
                      </td>
                      <td v-text="numberFormat(item.amount)" />
                      <td>
                        <v-tooltip v-if="item.comment" left>
                          <template v-slot:activator="{ on }">
                            <v-btn
                              v-on="on"
                              fab
                              dark
                              x-small
                              class="ml-1"
                              color="info"
                              @click="commentDialog(item)"
                            >
                              <v-icon> mdi-message-draw </v-icon>
                            </v-btn>
                          </template>
                          <span v-text="'Seg. médico comentario'" />
                        </v-tooltip>
                      </td>
                    </tr>
                    <tr>
                      <td />
                      <td />
                      <td />
                      <td />
                      <td />
                      <td />
                      <td />
                      <td />
                      <td />
                      <td />
                      <td />
                      <td>
                        <b v-text="numberFormat(icd.amount)" />
                      </td>
                      <td />
                    </tr>
                  </tbody>
                </template>
              </v-simple-table>
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
    </v-dialog>
    <v-dialog v-model="comment_dlg" scrollable persistent max-width="600px">
      <v-card v-if="comment" tile>
        <v-toolbar dark dense>
          <v-toolbar-title> FOLIO {{ comment.folio }}</v-toolbar-title>
          <v-spacer />
          <v-toolbar-items>
            <v-btn icon dark @click="comment_dlg = false">
              <v-icon> mdi-close </v-icon>
            </v-btn>
          </v-toolbar-items>
        </v-toolbar>
        <v-card-text>
          <v-row>
            <v-col cols="12">
              <v-spacer />
            </v-col>
            <v-col cols="12">
              <v-card class="mx-auto">
                <v-card-title>
                  <h2 class="text-caption" v-text="'SEG. MÉDICO COMENTARIO'" />
                </v-card-title>
                <v-divider />
                <v-card-text>
                  <p v-text="comment.comment" />
                </v-card-text>
              </v-card>
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
    </v-dialog>
    <v-dialog v-model="history_rep_dlg" scrollable persistent max-width="1800">
      <v-card
        tile
        :disabled="history_rep_dlg_ldg"
        :loading="history_rep_dlg_ldg"
      >
        <v-toolbar dark dense>
          <v-toolbar-title> HISTORIAL MÉDICO </v-toolbar-title>
          <v-spacer />
          <v-toolbar-items>
            <v-btn icon dark @click="history_rep_dlg = false">
              <v-icon> mdi-close </v-icon>
            </v-btn>
          </v-toolbar-items>
        </v-toolbar>
        <v-card-text v-if="history_rep">
          <v-row dense>
            <v-col cols="12" />
            <v-col cols="12">
              <v-divider />
            </v-col>
            <v-col cols="12">
              <h4 v-text="insured.credential + ' | ' + insured.full_name" />
            </v-col>
          </v-row>
          <v-row v-for="(rs, i) in history_rep.rss" :key="i" dense>
            <v-col cols="12">
              <v-spacer />
            </v-col>
            <v-col cols="12" xs="12" md="6">
              <ViewData
                :label="'Folio (' + (history_rep.rss.length - i) + ')'"
                :value="rs.folio"
              />
            </v-col>
            <v-col cols="12" xs="12" md="6">
              <ViewData label="F. creación" :value="rs.created_at" />
            </v-col>
            <v-col cols="12" xs="12" md="12">
              <ViewData label="Comentario" :value="rs.comment" />
            </v-col>
            <v-col cols="12" xs="12" md="12">
              <v-row v-for="(icd, j) in rs.icds" :key="j" dense>
                <v-col cols="2">
                  <ViewData
                    :label="'ICD (' + (j + 1) + ')'"
                    :value="icd.icd.code"
                  />
                </v-col>
                <v-col cols="10">
                  <ViewData label="Descripción" :value="icd.icd.icd" />
                </v-col>
              </v-row>
            </v-col>
            <v-col cols="12">
              <v-divider />
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
    </v-dialog>
    <v-dialog
      v-model="insured_category_dlg"
      scrollable
      persistent
      max-width="600"
    >
      <v-card
        tile
        :disabled="insured_category_dlg_ldg"
        :loading="insured_category_dlg_ldg"
      >
        <v-toolbar dark dense>
          <v-toolbar-title> CATEGORIZAR </v-toolbar-title>
          <v-spacer />
          <v-toolbar-items>
            <v-btn icon dark @click="insured_category_dlg = false">
              <v-icon> mdi-close </v-icon>
            </v-btn>
          </v-toolbar-items>
        </v-toolbar>
        <v-card-text v-if="insured_category">
          <v-form
            v-on:submit.prevent
            ref="insured_category_form"
            lazy-validation
          >
            <v-row dense class="pt-3">
              <v-col cols="12" />
              <v-col cols="12">
                <ViewData
                  label="N. asegurado"
                  :value="insured_category.credential"
                />
              </v-col>
              <v-col cols="12">
                <ViewData label="ID SM" :value="insured_category.enrollment" />
              </v-col>
              <v-col cols="12">
                <ViewData
                  label="Asegurado"
                  :value="insured_category.full_name"
                />
              </v-col>
              <v-col cols="12">
                <v-autocomplete
                  label="Categoría"
                  v-model="insured_category.insured_category_id"
                  :items="insured_categories"
                  item-value="id"
                  :item-text="(v) => v.name"
                  :loading="insured_categories_ldg"
                  dense
                  :rules="rules.required"
                />
              </v-col>

              <v-col cols="12">
                <v-btn block small color="success" @click="insuredCategorySave">
                  Aceptar
                  <v-icon right> mdi-check </v-icon>
                </v-btn>
              </v-col>
            </v-row>
          </v-form>
        </v-card-text>
      </v-card>
    </v-dialog>

    <v-dialog
      v-model="insured_medical_treatments_dlg"
      scrollable
      persistent
      max-width="1200"
    >
      <v-card
        tile
        :disabled="insured_medical_treatments_dlg_ldg"
        :loading="insured_medical_treatments_dlg_ldg"
      >
        <v-toolbar dark dense>
          <v-toolbar-title> OBSERVACIONES DEL TRATAMIENTO </v-toolbar-title>
          <v-spacer />
          <v-toolbar-items>
            <v-btn icon dark @click="insured_medical_treatments_dlg = false">
              <v-icon> mdi-close </v-icon>
            </v-btn>
          </v-toolbar-items>
        </v-toolbar>
        <v-card-text v-if="insured_medical_treatment">
          <v-row dense class="pt-3">
            <v-col cols="12" sm="12" md="4">
              <ViewData
                label="N. asegurado"
                :value="insured_medical_treatment.credential"
              />
            </v-col>
            <v-col cols="12" sm="12" md="4">
              <ViewData
                label="ID SM"
                :value="insured_medical_treatment.enrollment"
              />
            </v-col>
            <v-col cols="12" sm="12" md="4">
              <ViewData
                label="Asegurado"
                :value="insured_medical_treatment.full_name"
              />
            </v-col>
          </v-row>
          <v-row v-for="(item, i) in insured_medical_treatments" :key="i" dense>
            <v-col cols="12" xs="12" md="12">
              <ViewData
                :label="i + 1 + ') F. creación'"
                :value="item.created_at"
              />
            </v-col>
            <v-col cols="12" xs="12" md="12">
              <ViewData label="Observación" :value="item.observation" />
            </v-col>
            <v-col cols="12" xs="12" md="6">
              <ViewData label="F. seguimiento" :value="item.follow_up_date" />
            </v-col>
            <v-col cols="12" xs="12" md="6" class="text-right">
              <v-tooltip v-if="!item.revision" left>
                <template v-slot:activator="{ on }">
                  <v-btn
                    v-on="on"
                    color="warning"
                    dark
                    x-small
                    fab
                    class="ml-1"
                    @click.prevent="insuredMedicalTreatmentsRevision(item.id)"
                  >
                    <v-icon> mdi-check</v-icon>
                  </v-btn>
                </template>
                <span v-text="'Seguimiento realizado'" />
              </v-tooltip>
            </v-col>
            <v-col cols="12">
              <v-divider />
            </v-col>
          </v-row>

          <v-row
            v-if="
              insured_medical_treatments.length == 0 ||
              (insured_medical_treatments.length > 0 &&
                insured_medical_treatments[
                  insured_medical_treatments.length - 1
                ].revision)
            "
            dense
          >
            <v-col cols="12">
              <v-form
                v-on:submit.prevent
                ref="insured_medical_treatment_form"
                lazy-validation
              >
                <v-row dense>
                  <v-col cols="12" xs="12" md="12">
                    <v-textarea
                      v-model="insured_medical_treatment.observation"
                      label="Observación"
                      dense
                      :rules="rules.required"
                    />
                  </v-col>
                  <v-col cols="12" xs="12" md="12">
                    <DatePicker
                      label="F. seguimiento"
                      :model.sync="insured_medical_treatment.follow_up_date"
                      :rules="rules.required"
                      all_date
                    />
                  </v-col>
                  <v-col cols="12">
                    <v-btn
                      block
                      small
                      color="success"
                      @click="insuredMedicalTreatmentsStore"
                    >
                      Agregar
                      <v-icon right> mdi-plus </v-icon>
                    </v-btn>
                  </v-col>
                </v-row>
              </v-form>
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
    </v-dialog>
    <FaqDlg v-model="faqs_dlg" :faqs="faqs" />
  </v-card>
</template>

<script>
import Axios from "axios";
import {
  URL_API,
  headersToken,
  rules,
  dateTimeNow,
  msgConfirm,
  msgAlert,
} from "../../control";
import ViewData from "../../components/ViewData.vue";
import FaqDlg from "../../components/FaqDlg.vue";
import DatePicker from "../../components/DatePicker.vue";

export default {
  components: {
    ViewData,
    FaqDlg,
    DatePicker,
  },
  data() {
    return {
      login: this.$store.getters.getLogin,
      loading: false,
      tbl_data: [],
      tbl_search: "",
      tbl_headers: [
        {
          text: "#",
          value: "key",
          filterable: false,
          width: "50",
        },
        {
          text: "IDA",
          value: "id",
        },
        {
          text: "N. asegurado",
          value: "credential",
        },
        {
          text: "ID SM",
          value: "enrollment",
        },
        {
          text: "Asegurado",
          value: "full_name",
        },
        {
          text: "Contratante",
          value: "contractor.name",
        },
        {
          text: "Oficina",
          value: "office_address",
        },
        {
          text: "Tipo",
          value: "insured_type.insured_type",
        },
        {
          text: "ICD cód.",
          value: "icd.code",
        },
        {
          text: "ICD descrip.",
          value: "icd.icd",
        },
        {
          text: "F. seguimiento",
          value: "insured_medical_treatment.follow_up_date",
        },
        {
          text: "Seg. revisado",
          value: "insured_medical_treatment.revision",
        },
        {
          text: "Categoría",
          value: "insured_category.name",
        },
        {
          text: "",
          value: "action",
          filterable: false,
          fixed: true,
          sortable: false,
          width: "260",
        },
      ],
      contractors: [],
      contractors_ldg: true,
      contractor_ids: [],
      insured: null,
      sinister_rep: null,
      sinister_rep_dlg_ldg: false,
      sinister_rep_dlg: false,
      comment: null,
      comment_dlg: false,
      history_rep: null,
      history_rep_dlg_ldg: false,
      history_rep_dlg: false,
      insured_categories: [],
      insured_categories_ldg: [],
      insured_category: null,
      insured_category_dlg_ldg: false,
      insured_category_dlg: false,
      insured_medical_treatment: null,
      insured_medical_treatments: [],
      insured_medical_treatments_dlg_ldg: false,
      insured_medical_treatments_dlg: false,
      rules: rules(),
      faqs: [],
      faqs_dlg: false,
      faqs_ldg: true,
    };
  },
  methods: {
    numberFormat(v) {
      return Number(parseFloat(v).toFixed(2)).toLocaleString("en-US", {
        style: "currency",
        currency: "USD",
      });
    },
    getTableData() {
      this.loading = true;
      this.tbl_data = [];

      Axios.get(
        URL_API +
          "/insureds/medical/treatment?contractor_ids=" +
          this.contractor_ids,
        headersToken(this.login.token)
      ).then((res) => {
        this.tbl_data = res.data.data;
        this.loading = false;
      });
    },
    sinisterRepDlg(item) {
      this.insured = {
        credential: item.credential,
        full_name: item.full_name,
      };
      this.sinister_rep = null;
      this.sinister_rep_dlg_ldg = true;
      this.sinister_rep_dlg = true;

      Axios.get(
        URL_API +
          "/insureds/sinister/report?insured_id=" +
          item.id +
          "&icd_id=0",
        headersToken(this.login.token)
      ).then((res) => {
        this.sinister_rep = res.data.data;
        this.sinister_rep_dlg_ldg = false;
      });
    },
    commentDialog(item) {
      this.comment = item;
      this.comment_dlg = true;
    },
    historyRepDlg(item) {
      this.insured = {
        credential: item.credential,
        full_name: item.full_name,
      };
      this.history_rep = null;
      this.history_rep_dlg_ldg = true;
      this.history_rep_dlg = true;

      Axios.get(
        URL_API + "/rss/0/insured/" + item.id + "/history/icd",
        headersToken(this.login.token)
      ).then((res) => {
        this.history_rep = res.data.data;
        this.history_rep_dlg_ldg = false;
      });
    },
    insuredCategoryDlg(item) {
      this.insured_category = {
        id: item.id,
        insured_category_id: item.insured_category_id,
        credential: item.credential,
        enrollment: item.enrollment,
        full_name: item.full_name,
      };
      this.insured_category_dlg_ldg = false;
      this.insured_category_dlg = true;
    },
    insuredCategorySave() {
      if (this.$refs.insured_category_form.validate()) {
        this.$swal
          .fire(msgConfirm(`¿Confirma actualizar el registro?`))
          .then((res) => {
            if (res.isConfirmed) {
              this.insured_category_dlg_ldg = true;

              Axios.post(
                URL_API + "/insureds/category ",
                this.insured_category,
                headersToken(this.login.token)
              ).then((res) => {
                this.$swal.fire(
                  msgAlert(
                    res.data.success ? "success" : "error",
                    res.data.message
                  )
                );

                if (res.data.success) {
                  this.insured_category_dlg = false;
                  this.getTableData();
                } else {
                  console.log(res.data.message);
                }

                this.insured_category_dlg_ldg = false;
              });
            }
          });
      } else {
        this.$swal.fire(
          msgAlert(
            "error",
            "Formulario invalido, revisa los detalles señalados"
          )
        );
      }
    },
    insuredMedicalTreatmentsDlg(id, credential, enrollment, full_name) {
      this.insured_medical_treatments_dlg_ldg = true;
      this.insured_medical_treatments = [];
      this.insured_medical_treatment = {
        active: true,
        observation: "",
        follow_up_date: dateTimeNow().substring(0, 10),
        revision: false,
        insured_id: id,
        credential: credential,
        enrollment: enrollment,
        full_name: full_name,
      };
      this.insured_medical_treatments_dlg = true;

      Axios.get(
        URL_API +
          "/insureds/" +
          this.insured_medical_treatment.insured_id +
          "/medical/treatments/history",
        headersToken(this.login.token)
      ).then((res) => {
        this.insured_medical_treatments = res.data.data;
        this.insured_medical_treatments_dlg_ldg = false;
      });
    },
    insuredMedicalTreatmentsStore() {
      if (this.$refs.insured_medical_treatment_form.validate()) {
        this.$swal
          .fire(msgConfirm(`¿Confirma agregar la observación?`))
          .then((res) => {
            if (res.isConfirmed) {
              this.insured_medical_treatments_dlg_ldg = true;

              Axios.post(
                URL_API + "/insureds/medical/treatments",
                this.insured_medical_treatment,
                headersToken(this.login.token)
              ).then((res) => {
                this.$swal.fire(
                  msgAlert(
                    res.data.success ? "success" : "error",
                    res.data.message
                  )
                );

                if (res.data.success) {
                  this.insuredMedicalTreatmentsDlg(
                    this.insured_medical_treatment.insured_id,
                    this.insured_medical_treatment.credential,
                    this.insured_medical_treatment.enrollment,
                    this.insured_medical_treatment.full_name
                  );
                } else {
                  console.log(res.data.message);
                  this.insured_medical_treatments_dlg_ldg = false;
                }
              });
            }
          });
      } else {
        this.$swal.fire(
          msgAlert(
            "error",
            "Formulario invalido, revisa los detalles señalados"
          )
        );
      }
    },
    insuredMedicalTreatmentsRevision(id) {
      this.$swal
        .fire(msgConfirm("¿Confirma que el seguimiento fue realizado?"))
        .then((res) => {
          if (res.isConfirmed) {
            this.insured_medical_treatments_dlg_ldg = true;

            Axios.post(
              URL_API + "/insureds/medical/treatments/revision",
              {
                id: id,
                revision: true,
              },
              headersToken(this.login.token)
            ).then((res) => {
              this.$swal.fire(
                msgAlert(
                  res.data.success ? "success" : "error",
                  res.data.message
                )
              );

              if (res.data.success) {
                this.insuredMedicalTreatmentsDlg(
                  this.insured_medical_treatment.insured_id,
                  this.insured_medical_treatment.credential,
                  this.insured_medical_treatment.enrollment,
                  this.insured_medical_treatment.full_name
                );
              } else {
                console.log(res.data.message);
                this.insured_medical_treatments_dlg_ldg = false;
              }
            });
          }
        });
    },
    insuredMedicalTreatmentDesactivate(id) {
      this.$swal
        .fire(msgConfirm("¿Confirma quitar de tratamiento al asegurado?"))
        .then((res) => {
          if (res.isConfirmed) {
            this.loading = true;

            Axios.post(
              URL_API + "/insureds/medical/treatment",
              {
                id: id,
              },
              headersToken(this.login.token)
            ).then((res) => {
              this.$swal.fire(
                msgAlert(
                  res.data.success ? "success" : "error",
                  res.data.message
                )
              );

              if (res.data.success) {
                this.getTableData();
              } else {
                console.log(res.data.message);
                this.loading = false;
              }
            });
          }
        });
    },
  },
  mounted() {
    Axios.get(
      URL_API + "/faqs/faq_interfaces/" + this.$route.meta.title,
      headersToken(this.login.token)
    ).then((res) => {
      this.faqs = res.data.data;
      this.faqs_ldg = false;
    });

    Axios.get(
      URL_API + "/insureds/categories",
      headersToken(this.login.token)
    ).then((res) => {
      this.insured_categories = res.data.data;
      this.insured_categories_ldg = false;
    });

    Axios.get(URL_API + "/contractors", headersToken(this.login.token)).then(
      (res) => {
        this.contractors = res.data.data;
        this.contractors_ldg = false;
      }
    );
  },
};
</script>